import React, { useEffect } from "react"
import { isMobileOnly } from "react-device-detect"

const Page = () => {
  useEffect(() => {
    const encodedCurrentUrl = encodeURIComponent(window.location)
    const url = isMobileOnly
      ? `https://atmapp.page.link/?efr=1&ibi=com%2Eatm%2Eatm%2Eprod&isi=1507829573&apn=com%2Eatm%2Eatm%2Eprod&link=${encodedCurrentUrl}`
      : `${process.env.URL}/?plaid_oauth=production`
    window.location.assign(url)
  }, [])

  return null
}

export default Page
